import React from 'react';
import PropTypes from 'prop-types';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={css.root}>
      <p className={css.title}> Privacy Policy</p>
      <p
        style={{
          textAlign: 'center',
        }}
      >
        Last updated: 12/08/2023
      </p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and
        disclosure of Your information when You use the Service and tells You about Your privacy
        rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You
        agree to the collection and use of information in accordance with this Privacy Policy.
      </p>
      <p>Please read this Privacy Policy carefully before using Our Service.</p>
      <div className={css.list}>
        <p>1. Interpretation and Definitions </p>
        <ul>
          <li>
            1.1 Interpretation
            <p>
              The words of which the initial letter is capitalized have meanings defined under the
              following conditions. The following definitions shall have the same meaning regardless
              of whether they appear in the singular or in the plural.
            </p>
          </li>
          <li>
            1.2 Definitions
            <p>
              For the purposes of this Privacy Policy: <br />
              <p>
                <b>“Account”</b> means a unique account created for You to access our Service or
                parts of our Service
              </p>
              <p>
                <b>“Affiliate”</b> means an entity that controls, is controlled by, or is under
                common control with a party, where "control" means ownership of 50% or more of the
                shares, equity interest, or other securities entitled to vote for the election of
                directors or other managing authority.
              </p>
              <p>
                <b>“Application”</b> means the software program provided by the Company or Operator
                downloaded by You on any electronic device, named PatCom Education Connection.
              </p>
              <p>
                <b>“Business”</b> refers to the Company or Operator as the legal entity that
                collects Consumers' personal information and determines the purposes and means of
                the processing of Consumers' personal information, or on behalf of which such
                information is collected and that alone, or jointly with others, determines the
                purposes and means of the processing of consumers' personal information.
              </p>
              <p>
                <b> “Company” </b> (referred to as either "the Company", "We", "Us" or "Our" in this
                Agreement) refers to PatCom Medical Inc. and its subsidiaries. For the purpose of
                the GDPR, the Company or Operator is the Data Controller.
              </p>
              <p>
                <b>“Consumer”</b>, refers to You.
              </p>
              <p>
                <b> “Cookies”</b> are small files that are placed on Your computer, mobile device or
                any other device by a website, containing the details of Your browsing history on
                that website among its many uses.
              </p>
              <p>
                <b>“Country”</b> refers to United States of America and Canada
              </p>
              <p>
                <b>Data Controller</b>, for the purposes of the GDPR (General Data Protection
                Regulation), refers to the Company or Operator as the legal person which alone or
                jointly with others determines the purposes and means of the processing of Personal
                Data.
              </p>
              <p>
                <b>Device</b> means any device that can access the Service such as a computer, a
                cellphone, or a digital tablet.
              </p>
              <p>
                <b>“Do Not Track”</b>(DNT) is a concept that has been promoted by US regulatory
                authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                industry to develop and implement a mechanism for allowing internet users to control
                the tracking of their online activities across websites.
              </p>
              <p>
                <b>“Operator”</b> (referred to as either "the Operator", "We", "Us" or "Our" in this
                Agreement) refers to PatCom Education Connection. For the purpose of the GDPR, the
                Company or Operator is the Data Controller.
              </p>
              <p>
                <b>“Personal Data”</b> is any information that relates to an identified or
                identifiable individual. For the purposes of GDPR, Personal Data means any
                information relating to You such as a name, an identification number, location data,
                online identifier or to one or more factors specific to the physical, physiological,
                genetic, mental, economic, cultural or social identity. For the purposes of the
                CCPA, Personal Data means any information that identifies, relates to, describes or
                is capable of being associated with, or could reasonably be linked, directly or
                indirectly, with You.
              </p>
              <p>
                <b>“Sale”</b>, means selling, renting, releasing, disclosing, disseminating, making
                available, transferring, or otherwise communicating orally, in writing, or by
                electronic or other means, a Consumer's personal information to another business or
                a third party for monetary or other valuable consideration.
              </p>
              <p>
                <b>“Service”</b> refers to the Application or the Website or both.
              </p>
              <p>
                <b>“Service Provider”</b> means any natural or legal person who processes the data
                on behalf of the Company or Operator. It refers to third-party companies or
                individuals employed by the Company or Operator to facilitate the Service, to
                provide the Service on behalf of the Company or Operator, to perform services
                related to the Service or to assist the Company or Operator in analyzing how the
                Service is used. For the purpose of the GDPR, Service Providers are considered Data
                Processors.
              </p>
              <p>
                <b>“Third-party Social Media Service”</b> refers to any website or any social
                network website through which a User can log in or create an account to use the
                Service.
              </p>
              <p>
                <b>“Usage Data”</b> refers to data collected automatically, either generated by the
                use of the Service or from the Service infrastructure itself (for example, the
                duration of a page visit). “Website” refers to PatCom Education Connection
                accessible from{' '}
                <a href="https://www.slp-education.com">https://www.slp-education.com</a>
              </p>
              <p>
                <b>“You”</b> means the individual accessing or using the Service, or the company, or
                other legal entity on behalf of which such individual is accessing or using the
                Service, as applicable. Under GDPR (General Data Protection Regulation), You can be
                referred to as the Data Subject or as the User as you are the individual using the
                Service.
              </p>
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>2. Contact Us </p>
        <p>If you have any questions about these Terms of Service, You can contact us:</p>
        <ul className={css.listStyleTwo}>
          <li>
            By e-mail: <a href="mailto:info@patcommedical.com">info@patcommedical.com</a>
          </li>
          <li>
            By visiting this page on our website:
            <a href="www.patcommedical.com/contact-us">www.patcommedical.com/contact-us</a>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>3. Collecting and Using Your Personal Data</p>
        <ul>
          <li>
            3.1 Types of Data Collected
            <p className={css.specificSubTitle}>3.1.1 Personal Data</p>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally
              identifiable information that can be used to contact or identify You. Personally
              identifiable information may include, but is not limited to:
            </p>
          </li>
          <ul className={css.listStyleTwo}>
            <li>Email address</li>
            <li> First name and last name</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Bank account information</li>
            <li>Credit card number and expiration date</li>
            <li>Usage Data</li>
          </ul>
          <p className={css.specificSubTitle}>3.1.2 Usage Data</p>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet Protocol address (e.g.
            IP address), browser type, browser version, the pages of our Service that You visit, the
            time and date of Your visit, the time spent on those pages, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may collect certain
            information automatically, including, but not limited to, the type of mobile device You
            use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You visit our Service
            or when You access the Service by or through a mobile device.
          </p>
          <p className={css.specificSubTitle}>
            3.1.3 Information from Third-Party Social Media Services
          </p>
          <p>
            The Company or Operator may allow You to create an account and log in to use the Service
            through the following Third-party Social Media Services. These Third-party Social Media
            Services, may include, but is not limited to:
          </p>
          <ul className={css.listStyleTwo}>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
            <li>LinkedIn</li>
            <li>Apple</li>
          </ul>
          <p>
            If You decide to register through or otherwise grant us access to a Third-Party Social
            Media Service, We may collect Personal data that is already associated with Your
            Third-Party Social Media Service's account, such as Your name, Your email address, Your
            activities or Your contact list associated with that account.
          </p>
          <p>
            You may also have the option of sharing additional information with the Company or
            Operator through Your Third-Party Social Media Service's account. If You choose to
            provide such information and Personal Data, during registration or otherwise, You are
            giving the Company or Operator permission to use, share, and store it in a manner
            consistent with this Privacy Policy.
          </p>
          <p className={css.specificSubTitle}>3.1.4 Tracking Technologies and Cookies</p>
          <p>
            We use Cookies and similar tracking technologies to track the activity on Our Service
            and store certain information. Tracking technologies used are beacons, tags, and scripts
            to collect and track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <p>
            <b> “Cookies or Browser Cookies.”</b> A cookie is a small file placed on Your Device.
            You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is
            being sent. However, if You do not accept Cookies, You may not be able to use some parts
            of our Service. Unless you have adjusted Your browser setting so that it will refuse
            Cookies, our Service may use Cookies.
          </p>
          <p>
            <b> “Web Beacons.”</b> Certain sections of our Service and our emails may contain small
            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
            single-pixel gifs) that permit the Company or Operator, for example, to count users who
            have visited those pages or opened an email and for other related application or website
            statistics (for example, recording the popularity of a certain section and verifying
            system and server integrity).
          </p>
          <p>
            Cookies can be <b>"Persistent" or "Session"</b> Cookies. Persistent Cookies remain on
            Your personal computer or mobile device when You go offline, while Session Cookies are
            deleted as soon as You close Your web browser.
          </p>
          <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
          <ul className={css.listStyleTwo}>
            <li> “Necessary / Essential Cookies”</li>
            <li>Type: Session Cookies </li>
            <li>Administered by: Us </li>
            <li>
              Purpose: These Cookies are essential to provide You with services available through
              the Application or Website and to enable You to use some of its features. They help to
              authenticate users and prevent fraudulent use of user accounts. Without these Cookies,
              the services that You have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </li>
            <li> “Cookies Policy / Notice Acceptance Cookies”</li>
            <li>Type: Persistent Cookies </li>
            <li>Administered by: Us </li>
            <li>
              Purpose: These Cookies identify if users have accepted the use of cookies on the
              Application or Website.
            </li>
            <li>“Functionality Cookies” </li>
            <li>Type: Persistent Cookies </li>
            <li>Administered by: Us </li>
            <li>
              Purpose: These Cookies allow us to remember choices You make when You use the
              Application or Website, such as remembering your login details or language preference.
              The purpose of these Cookies is to provide You with a more personal experience and to
              avoid You having to re-enter your preferences every time You use the Application or
              Website.
            </li>
            <li>“Tracking and Performance Cookies” </li>
            <li>Type: Persistent Cookies </li>
            <li>Administered by: Third-Parties </li>
          </ul>
          <p>
            Purpose: These Cookies are used to track information about traffic to the Application or
            Website and how users use the Application or Website. The information gathered via these
            Cookies may directly or indirectly identify you as an individual visitor. This is
            because the information collected is typically linked to a pseudonymous identifier
            associated with the device you use to access the Application or Website. We may also use
            these Cookies to test new pages, features or new functionality of the Application or
            Website to see how our users react to them.
          </p>
          <li>
            3.2 Use of Your Personal Data
            <p>The Company or Operator may use Personal Data for the following purposes:</p>
            <p>
              <b>“To provide and maintain our Service”</b>, including to monitor the usage of our
              Service.
            </p>
            <p>
              <b>“To manage Your Account:”</b> to manage Your registration as a user of the Service.
              The Personal Data You provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </p>
            <p>
              <b>“For the performance of a contract:” </b> the development, compliance and
              undertaking of the purchase contract for the products, items or services You have
              purchased or of any other contract with Us through the Service.
            </p>
            <p>
              <b>“To contact You:” </b> To contact You by email, telephone calls, SMS, or other
              equivalent forms of electronic communication, such as a mobile application's push
              notifications regarding updates or informative communications related to the
              functionalities, products or contracted services, including the security updates, when
              necessary or reasonable for their implementation.
            </p>
            <p>
              <b>“To provide You” </b> with news, special offers and general information about other
              goods, services and events which we offer that are similar to those that you have
              already purchased or enquired about unless You have opted not to receive such
              information.
            </p>
            <p>
              <b> “To manage Your requests:”</b> To attend and manage Your requests to Us.
            </p>
            <p>
              <b> “To deliver targeted advertising to You”:</b> We may use Your information to
              develop and display content and advertising (and work with third-party vendors who do
              so) tailored to Your interests and/or location and to measure its effectiveness.
            </p>
            <p>
              <b>“For business transfers:” </b> We may use Your information to evaluate or conduct a
              merger, divestiture, restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
              about our Service users is among the assets transferred.
            </p>
            <p>
              <b>“For other purposes”: </b> We may use Your information for other purposes, such as
              data analysis, identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
            <p>We may share Your personal information in the following situations:</p>
            <p>
              <b>“With Service Providers:”</b> We may share Your personal information with Service
              Providers to monitor and analyze the use of our Service, to advertise on third party
              websites to You after You visited our Service, for payment processing, to contact You.
            </p>
            <p>
              <b>“For business transfers:” </b> We may share or transfer Your personal information
              in connection with, or during negotiations of, any merger, sale of Company or Operator
              assets, financing, or acquisition of all or a portion of Our business to another
              company.
            </p>
            <p>
              <b>“With Affiliates:”</b> We may share Your information with Our affiliates, in which
              case we will require those affiliates to honor this Privacy Policy. Affiliates include
              Our parent company and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with Us.
            </p>
            <p>
              <b>“With business partners:”</b> We may share Your information with Our business
              partners to offer You certain products, services or promotions.
            </p>
            <p>
              <b>“With other users:”</b> when You share personal information or otherwise interact
              in the public areas with other users, such information may be viewed by all users and
              may be publicly distributed outside. If You interact with other users or register
              through a Third-Party Social Media Service, Your contacts on the Third-Party Social
              Media Service may see Your name, profile, pictures and description of Your activity.
              Similarly, other users will be able to view descriptions of Your activity, communicate
              with You and view Your profile.
            </p>
            <p>
              <b> “With Your consent”:</b> We may disclose Your personal information for any other
              purpose with Your consent.
            </p>
          </li>
          <li>
            3.3 Retention of Your Personal Data
            <p>
              The Company or Operator will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will retain and use Your
              Personal Data to the extent necessary to comply with our legal obligations (for
              example, if we are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company or Operator will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period of time, except when this data
              is used to strengthen the security or to improve the functionality of Our Service, or
              We are legally obligated to retain this data for longer time periods.
            </p>
            <p>
              When Your Personal Data is no longer required by law or rights or obligations by Us or
              You, We will delete the Personal Data. In most cases, Personal Data will be deleted
              upon termination or expiry of the agreement between the You and the Company or
              Operator or upon Your written request.
            </p>
          </li>
          <li>
            3.4 Transfer of Your Personal Data
            <p>
              Your information, including Personal Data, is processed at the Company’s or Operator's
              operating offices and in any other places where the parties involved in the processing
              are located. It means that this information may be transferred to — and maintained on
              — computers located outside of Your state, province, country or other governmental
              jurisdiction where the data protection laws may differ from those from Your
              jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of such information
              represents Your agreement to that transfer.
            </p>
            <p>
              The Company or Operator will take all steps reasonably necessary to ensure that Your
              data is treated securely and in accordance with this Privacy Policy and no transfer of
              Your Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of Your data and other personal
              information.
            </p>
          </li>
          <li>
            3.5 Delete Your Personal Data
            <p>
              You have the right to delete or request that We assist in deleting the Personal Data
              that We have collected about You.
            </p>
            <p>
              Our Service may give You the ability to delete certain information about You from
              within the Service.
            </p>
            <p>
              You may update, amend, or delete Your information at any time by signing in to Your
              Account, if you have one, and visiting the account settings section that allows you to
              manage Your personal information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided to Us.
            </p>
            <p>
              Please note, however, that We may need to retain certain information when we have a
              legal obligation or lawful basis to do so.
            </p>
          </li>
          <li>
            3.6 Disclosure of Your Personal Data
            <p className={css.specificSubTitle}>3.6.1 Business Transactions</p>
            <p>
              The Company or Operator may disclose Your Personal Data in the good faith belief that
              such action is necessary to:
            </p>
            <ul className={css.listStyleTwo}>
              <li> Comply with a legal obligation</li>
              <li> Protect and defend the rights or property of the Company or Operator</li>
              <li> Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li> Protect the personal safety of Users of the Service or the public</li>
              <li> Protect against legal liability</li>
            </ul>
          </li>
          <li>
            3.7 Security of Your Personal Data
            <p>
              The security of Your Personal Data is important to Us, but remember that no method of
              transmission over the Internet, or method of electronic storage is 100% secure. While
              We strive to use commercially acceptable means to protect Your Personal Data, We
              cannot guarantee its absolute security.
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>4. Detailed Information on the Processing of Your Personal Data</p>
        <ul>
          <li>
            4.1 Analytics
            <p>
              We may use third-party Service providers to monitor and analyze the use of our
              Service. They may include, but are not limited to:
            </p>
            <p>
              <b>“Google Analytics” </b>
              <br />
              Google Analytics is a web analytics service offered by Google that tracks and reports
              website traffic. Google uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google may use the collected
              data to contextualize and personalize the ads of its own advertising network. <br />
              You can opt-out of having made your activity on the Service available to Google
              Analytics by installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
              information with Google Analytics about visits activity.
              <br /> You may opt-out of certain Google Analytics features through your mobile device
              settings, such as your device advertising settings or by following the instructions
              provided by Google in their Privacy Policy:
              <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
              For more information on the privacy practices of Google, please visit the Google
              Privacy & Terms web page:
              <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
            </p>
          </li>
          <li>
            4.2 Email Marketing
            <p>
              We may use Your Personal Data to contact You with newsletters, marketing or
              promotional materials and other information that may be of interest to You. You may
              opt-out of receiving any, or all, of these communications from Us by following the
              unsubscribe link or instructions provided in any email We send or by contacting Us.
            </p>
            <p>
              We may use Email Marketing Service Providers to manage and send emails to You. They
              may include, but are not limited to:
            </p>
            <p>
              <b>“Mailchimp”</b> <br />
              Mailchimp is an email marketing sending service provided by The Rocket Science Group
              LLC. <br />
              For more information on the privacy practices of Mailchimp, please visit their Privacy
              policy:
              <a href="https://mailchimp.com/legal/privacy/">
                https://mailchimp.com/legal/privacy/
              </a>
            </p>
          </li>
          <li>
            4.3 Payments
            <p>
              We may provide paid products and/or services within the Service. In that case, we may
              use third-party services for payment processing (e.g. payment processors).
            </p>
            <p>
              We will not store or collect Your payment card details. That information is provided
              directly to Our third-party payment processors whose use of Your personal information
              is governed by their Privacy Policy. These payment processors adhere to the standards
              set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
              requirements help ensure the secure handling of payment information.
            </p>
            <p>They may include, but are not limited to:</p>
            <p>
              <b>“Stripe”</b> <br />
              Their Privacy Policy can be viewed at
              <a href="https://stripe.com/us/privacy">(https://stripe.com/us/privacy)</a>
            </p>
            <p>
              <b>“PayPal”</b> <br />
              Their Privacy Policy can be viewed at
              <a href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">
                (https://www.paypal.com/us/webapps/mpp/ua/privacy-full)
              </a>
            </p>
          </li>
          <li>
            4.4 Behavioral Remarketing
            <p>
              The Company or Operator uses remarketing services to advertise to You after You
              accessed or visited our Service. We and Our third-party vendors use cookies and
              non-cookie technologies to help Us recognize Your Device and understand how You use
              our Service so that We can improve our Service to reflect Your interests and serve You
              advertisements that are likely to be of more interest to You.
            </p>
            <p>
              These third-party vendors collect, store, use, process and transfer information about
              Your activity on Our Service in accordance with their Privacy Policies and to enable
              Us to:
            </p>
            <p>Measure and analyze traffic and browsing activity on Our Service</p>
            <p>
              Show advertisements for our products and/or services to You on third-party websites or
              apps
            </p>
            <p> Measure and analyze the performance of Our advertising campaigns</p>
            <p>
              Some of these third-party vendors may use non-cookie technologies that may not be
              impacted by browser settings that block cookies. Your browser may not permit You to
              block such technologies. You can use the following third-party tools to decline the
              collection and use of information for the purpose of serving You interest-based
              advertising:
            </p>
            <p>
              The NAI's opt-out platform:{' '}
              <a href="http://www.networkadvertising.org/choices/">
                (http://www.networkadvertising.org/choices/)
              </a>
            </p>
            <p>
              The EDAA's opt-out platform:{' '}
              <a href="http://www.youronlinechoices.com/">(http://www.youronlinechoices.com/)</a>
            </p>
            <p>
              The DAA's opt-out platform:{' '}
              <a href="http://optout.aboutads.info/?c=2&lang=EN">
                (http://optout.aboutads.info/?c=2&lang=EN)
              </a>
            </p>
            <p>
              You may opt-out of all personalized advertising by enabling privacy features on Your
              mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization
              (Android). See Your mobile device Help system for more information.
            </p>
            <p>
              We may share information, such as hashed email addresses (if available) or other
              online identifiers collected on Our Service with these third-party vendors. This
              allows Our third-party vendors to recognize and deliver You ads across devices and
              browsers. To read more about the technologies used by these third-party vendors and
              their cross-device capabilities please refer to the Privacy Policy of each vendor
              listed below.
            </p>
            <p>The third-party vendors We may use are, but are not limited to:</p>
            <p>
              <b>“Google Ads (AdWords)” </b>
            </p>
            <p>
              Google Ads (AdWords) remarketing service is provided by Google Inc. <br />
              You can opt-out of Google Analytics for Display Advertising and customise the Google
              Display Network ads by visiting the Google Ads Settings page:
              <a href="http://www.google.com/settings/ads"> (http://www.google.com/settings/ads)</a>
              . Google also recommends installing the Google Analytics Opt-out Browser Add-on
              <a href="https://tools.google.com/dlpage/gaoptout">
                (https://tools.google.com/dlpage/gaoptout)
              </a>
              for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with
              the ability to prevent their data from being collected and used by Google Analytics.
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google
              Privacy & Terms web page:
              <a href="https://policies.google.com/privacy">
                (https://policies.google.com/privacy)
              </a>
            </p>
            <p>
              <b>“Twitter” </b>
            </p>
            <p>
              Twitter remarketing service is provided by Twitter Inc.
              <br />
              You can opt-out from Twitter's interest-based ads by following their instructions:
              <br />
              <a href="https://support.twitter.com/articles/20170405">
                (https://support.twitter.com/articles/20170405)
              </a>
              . <br /> You can learn more about the privacy practices and policies of Twitter by
              visiting their Privacy Policy page: <br />
              <a href="https://twitter.com/privacy">(https://twitter.com/privacy)</a>
            </p>
            <p>
              <b>“Facebook / Meta” </b>
            </p>
            <p>
              Facebook or Meta remarketing service is provided by Facebook Inc. and Meta Inc.
              <br />
              You can learn more about interest-based advertising from Facebook by visiting this
              page:
              <a href="https://www.facebook.com/help/516147308587266">
                (https://www.facebook.com/help/516147308587266)
              </a>
              . <br /> To opt-out from Facebook's interest-based ads, follow these instructions from
              Facebook:
              <a href="https://www.facebook.com/help/568137493302217">
                (https://www.facebook.com/help/568137493302217)
              </a>
              . <br /> Facebook adheres to the Self-Regulatory Principles for Online Behavioural
              Advertising established by the Digital Advertising Alliance. You can also opt-out from
              Facebook and other participating companies through the Digital Advertising Alliance in
              the USA
              <a href="http://www.aboutads.info/choices/">(http://www.aboutads.info/choices/)</a>,
              the Digital Advertising Alliance of Canada in Canada
              <a href="http://youradchoices.ca/">(http://youradchoices.ca/)</a> or the European
              Interactive Digital Advertising Alliance in Europe
              <a href="http://www.youronlinechoices.eu/">(http://www.youronlinechoices.eu/)</a>, or
              opt-out using your mobile device settings. <br /> For more information on the privacy
              practices of Facebook, please visit Facebook's Data Policy:
              <a href="https://www.facebook.com/privacy/explanation">
                (https://www.facebook.com/privacy/explanation)
              </a>
            </p>
            <p>
              <b>“Pinterest” </b>
            </p>
            <p>
              Pinterest remarketing service is provided by Pinterest Inc.
              <br />
              You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track"
              functionality of your web browser or by following Pinterest instructions: <br />
              <a href="http://help.pinterest.com/en/articles/personalization-and-data">
                (http://help.pinterest.com/en/articles/personalization-and-data)
              </a>
              .
              <br /> You can learn more about the privacy practices and policies of Pinterest by
              visiting their Privacy Policy page:
              <a href="https://about.pinterest.com/en/privacy-policy">
                (https://about.pinterest.com/en/privacy-policy)
              </a>
            </p>
          </li>
          <li>
            4.5 Usage, Performance and Miscellaneous
            <p>
              We may use third-party Service Providers to provide better improvement of our Service.
              They may include, but are not limited to:
            </p>
            <p>
              <b>“Intercom” </b>
              <br />
              Their Privacy Policy can be viewed at
              <a href="https://www.intercom.com/legal/privacy">
                (https://www.intercom.com/legal/privacy)
              </a>
            </p>
            <p>
              <b>“Facebook Messenger” </b>
              <br />
              Their Privacy Policy can be viewed at
              <a href="https://www.facebook.com/privacy/policy">
                (https://www.facebook.com/privacy/policy)
              </a>
            </p>
            <p>
              <b>“Zendesk” </b>
              <br />
              Their Privacy Policy can be viewed at
              <a href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/">
                (https://www.zendesk.com/company/agreements-and-terms/privacy-notice/)
              </a>
            </p>
            <p>
              <b>“Tawk” </b>
              <br />
              Their Privacy Policy can be viewed at
              <a href="https://www.tawk.to/privacy-policy">(https://www.tawk.to/privacy-policy)</a>
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p> 5. Links to Other Websites</p>
        <ul>
          <li>
            <p>
              Our Service may contain links to other websites that are not operated by Us. If You
              click on a third party link, You will be directed to that third party's site. We
              strongly advise You to review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>6. GDPR Privacy</p>
        <ul>
          <li>
            <p>
              <b>6.1 Legal Basis for Processing Personal Data under GDPR</b>
            </p>
            <p>We may process Personal Data under the following conditions:</p>
            <p>
              <b> “Consent:”</b> You have given Your consent for processing Personal Data for one or
              more specific purposes.
            </p>
            <p>
              <b> “Performance of a contract:”</b> Provision of Personal Data is necessary for the
              performance of an agreement with You and/or for any pre-contractual obligations
              thereof.
            </p>
            <p>
              <b>“Legal obligations:” </b>Processing Personal Data is necessary for compliance with
              a legal obligation to which the Company or Operator is subject.
            </p>
            <p>
              <b>“Vital interests:”</b> Processing Personal Data is necessary in order to protect
              Your vital interests or of another natural person.
            </p>
            <p>
              <b> “Public interests:”</b> Processing Personal Data is related to a task that is
              carried out in the public interest or in the exercise of official authority vested in
              the Company or Operator.
            </p>
            <p>
              <b> “Legitimate interests:”</b> Processing Personal Data is necessary for the purposes
              of the legitimate interests pursued by the Company or Operator.
            </p>
            <p>
              In any case, the Company or Operator will gladly help to clarify the specific legal
              basis that applies to the processing, and in particular whether the provision of
              Personal Data is a statutory or contractual requirement, or a requirement necessary to
              enter into a contract.
            </p>
          </li>
          <li>
            <p>
              <b>6.2 Your Rights under the GDPR</b>
            </p>
            <p>
              The Company or Operator undertakes to respect the confidentiality of Your Personal
              Data and to guarantee You can exercise Your rights.
            </p>
            <p>
              You have the right under this Privacy Policy, and by law if You are within the EU, to:
            </p>
            <p>
              <b>“Request access to Your Personal Data.”</b> The right to access, update or delete
              the information We have on You. Whenever made possible, you can access, update or
              request deletion of Your Personal Data directly within Your account settings section.
              If you are unable to perform these actions yourself, please contact Us to assist You.
              This also enables You to receive a copy of the Personal Data We hold about You.
            </p>
            <p>
              <b>“Request correction of the Personal Data that We hold about You.”</b> You have the
              right to have any incomplete or inaccurate information We hold about You corrected.
            </p>
            <p>
              <b> “Object to processing of Your Personal Data.”</b> This right exists where We are
              relying on a legitimate interest as the legal basis for Our processing and there is
              something about Your particular situation, which makes You want to object to our
              processing of Your Personal Data on this ground. You also have the right to object
              where We are processing Your Personal Data for direct marketing purposes.
            </p>
            <p>
              <b>“Request erasure of Your Personal Data.”</b> You have the right to ask Us to delete
              or remove Personal Data when there is no good reason for Us to continue processing it.
            </p>
            <p>
              <b>“Request the transfer of Your Personal Data.”</b> We will provide to You, or to a
              third-party You have chosen, Your Personal Data in a structured, commonly used,
              machine-readable format. Please note that this right only applies to automated
              information which You initially provided consent for Us to use or where We used the
              information to perform a contract with You.
            </p>
            <p>
              <b> “Withdraw Your consent.”</b> You have the right to withdraw Your consent on using
              your Personal Data. If You withdraw Your consent, We may not be able to provide You
              with access to certain specific functionalities of the Service.
            </p>
          </li>
          <li>
            <p>
              <b>6.3 Exercising of Your GDPR Data Protection Rights</b>
            </p>
            <p>
              You may exercise Your rights of access, rectification, cancellation and opposition by
              contacting Us. Please note that we may ask You to verify Your identity before
              responding to such requests. If You make a request, We will try our best to respond to
              You as soon as possible.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority about Our collection and
              use of Your Personal Data. For more information, if You are in the European Economic
              Area (EEA), please contact Your local data protection authority in the EEA.
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>7. CCPA, CalOPPA, and California Privacy Rights</p>
        <ul>
          <li>
            <p>
              This privacy notice section for California residents supplements the information
              contained in Our Privacy Policy and it applies solely to all visitors, users, and
              others who reside in the State of California.
            </p>
          </li>
          <li>
            <p>
              <b>7.1 Categories of Personal Information Collected</b>
            </p>
            <p>
              We collect information that identifies, relates to, describes, references, is capable
              of being associated with, or could reasonably be linked, directly or indirectly, with
              a particular Consumer or Device. The following is a list of categories of personal
              information which we may collect or may have been collected from California residents
              within the last twelve (12) months.
            </p>
            <p>
              Please note that the categories and examples provided in the list below are those
              defined in the CCPA. This does not mean that all examples of that category of personal
              information were in fact collected by Us, but reflects our good faith belief to the
              best of our knowledge that some of that information from the applicable category may
              be and may have been collected. For example, certain categories of personal
              information would only be collected if You provided such personal information directly
              to Us.
            </p>
            <p>
              <b> “Category A: Identifiers.”</b> <br />
              Examples: A real name, alias, postal address, unique personal identifier, online
              identifier, Internet Protocol address, email address, account name, driver's license
              number, passport number, or other similar identifiers. <br /> Collected: Yes.
            </p>
            <p>
              <b>
                “Category B: Personal information categories listed in the California Customer
                Records statute (Cal. Civ. Code § 1798.80(e)).”
              </b>
              <br /> Examples: A name, signature, Social Security number, physical characteristics
              or description, address, telephone number, passport number, driver's license or state
              identification card number, insurance policy number, education, employment, employment
              history, bank account number, credit card number, debit card number, or any other
              financial information, medical information, or health insurance information. Some
              personal information included in this category may overlap with other categories.
              <br /> Collected: Yes.
            </p>
            <p>
              <b>
                “Category C: Protected classification characteristics under California or federal
                law.”
              </b>
              <br />
              Examples: Age (40 years or older), race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition, physical or mental
              disability, sex (including gender, gender identity, gender expression, pregnancy or
              childbirth and related medical conditions), sexual orientation, veteran or military
              status, genetic information (including familial genetic information). <br />
              Collected: No.
            </p>
            <p>
              <b>“Category D: Commercial information.” </b> <br />
              Examples: Records and history of products or services purchased or considered. <br />
              Collected: Yes.
            </p>
            <p>
              <b> “Category E: Biometric information.” </b> <br />
              Examples: Genetic, physiological, behavioral, and biological characteristics, or
              activity patterns used to extract a template or other identifier or identifying
              information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans,
              keystroke, gait, or other physical patterns, and sleep, health, or exercise data.
              <br />
              Collected: No.
            </p>
            <p>
              <b>“Category F: Internet or other similar network activity.”</b> <br />
              Examples: Interaction with our Service or advertisement. <br />
              Collected: Yes.
            </p>
            <p>
              <b> “Category G: Geolocation data.”</b> <br />
              Examples: Approximate physical location. <br />
              Collected: No.
            </p>
            <p>
              <b> “Category H: Sensory data.” </b>
              <br />
              Examples: Audio, electronic, visual, thermal, olfactory, or similar information.
              <br />
              Collected: No.
            </p>
            <p>
              <b>“Category I: Professional or employment-related information.” </b> <br />
              Examples: Current or past job history or performance evaluations. <br />
              Collected: No.
            </p>
            <p>
              <b>
                “Category J: Non-public education information (per the Family Educational Rights and
                Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).”
              </b>
              <br />
              Examples: Education records directly related to a student maintained by an educational
              institution or party acting on its behalf, such as grades, transcripts, class lists,
              student schedules, student identification codes, student financial information, or
              student disciplinary records. <br /> Collected: No.
            </p>
            <p>
              <b> “Category K: Inferences drawn from other personal information.”</b> <br />
              Examples: Profile reflecting a person's preferences, characteristics, psychological
              trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
              <br />
              Collected: No.
            </p>
            <p>
              Under CCPA, personal information does not include: <br />
              Publicly available information from government records
              <br />
              Deidentified or aggregated consumer information
              <br />
              Information excluded from the CCPA's scope, such as:
              <br />
              Health or medical information covered by the Health Insurance Portability and
              Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
              Information Act (CMIA) or clinical trial data <br /> Personal Information covered by
              certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA),
              the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act
              (FIPA), and the Driver's Privacy Protection Act of 1994
            </p>
          </li>
          <li>
            <p>
              <b>7.2 Sources of Personal Information</b>
            </p>
            <p>
              We obtain the categories of personal information listed above from the following
              categories of sources:
            </p>
            <p>
              <b> “Directly from You”.</b> For example, from the forms You complete on our Service,
              preferences You express or provide through our Service, or from Your purchases on our
              Service.
              <br />
              <b> “Indirectly from You” </b>. For example, from observing Your activity on our
              Service.
              <br />
              <b> “Automatically from You”.</b> For example, through cookies We or our Service
              Providers set on Your Device as You navigate through our Service.
              <br />
              <b> “From Service Providers”.</b> For example, third-party vendors to monitor and
              analyze the use of our Service, third-party vendors to deliver targeted advertising to
              You, third-party vendors for payment processing, or other third-party vendors that We
              use to provide the Service to You.
            </p>
          </li>
          <li>
            <p>
              <b>7.3 Use of Personal Information for Business Purposes or Commercial Purposes</b>
            </p>
            <p>
              We may use or disclose personal information We collect for "business purposes" or
              "commercial purposes" (as defined under the CCPA), which may include the following
              examples:
            </p>
            <p>
              To operate our Service and provide You with our Service. <br /> To provide You with
              support and to respond to Your inquiries, including to investigate and address Your
              concerns and monitor and improve our Service.
              <br /> To fulfill or meet the reason You provided the information. For example, if You
              share Your contact information to ask a question about our Service, We will use that
              personal information to respond to Your inquiry. If You provide Your personal
              information to purchase a product or service, We will use that information to process
              Your payment and facilitate delivery. <br />
              To respond to law enforcement requests and as required by applicable law, court order,
              or governmental regulations. As described to You when collecting Your personal
              information or as otherwise set forth in the CCPA.
              <br /> For internal administrative and auditing purposes.
              <br /> To detect security incidents and protect against malicious, deceptive,
              fraudulent or illegal activity, including, when necessary, to prosecute those
              responsible for such activities.
            </p>
            <p>
              Please note that the examples provided above are illustrative and not intended to be
              exhaustive. For more details on how we use this information, please refer to the "Use
              of Your Personal Data" section.
            </p>
            <p>
              If We decide to collect additional categories of personal information or use the
              personal information We collected for materially different, unrelated, or incompatible
              purposes We will update this Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              <b>
                7.4 Disclosure of Personal Information for Business Purposes or Commercial Purposes
              </b>
            </p>
            <p>
              We may use or disclose and may have used or disclosed in the last twelve (12) months
              the following categories of personal information for business or commercial purposes:
            </p>
            <p>
              Category A: Identifiers <br />
              Category B: Personal information categories listed in the California Customer Records
              statute (Cal. Civ. Code § 1798.80(e)) <br />
              Category D: Commercial information <br />
              Category F: Internet or other similar network activity
            </p>
            <p>
              Please note that the categories listed above are those defined in the CCPA. This does
              not mean that all examples of that category of personal information were in fact
              disclosed, but reflects our good faith belief to the best of our knowledge that some
              of that information from the applicable category may be and may have been disclosed.
            </p>
            <p>
              When We disclose personal information for a business purpose or a commercial purpose,
              We enter a contract that describes the purpose and requires the recipient to both keep
              that personal information confidential and not use it for any purpose except
              performing the contract.
            </p>
          </li>
          <li>
            <p>
              <b>7.5 Sale of Personal Information</b>
            </p>
            <p>
              As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing,
              disclosing, disseminating, making available, transferring, or otherwise communicating
              orally, in writing, or by electronic or other means, a consumer's personal information
              by the business to a third party for valuable consideration. This means that We may
              have received some kind of benefit in return for sharing personal information, but not
              necessarily a monetary benefit.
            </p>
            <p>
              Please note that the categories listed below are those defined in the CCPA. This does
              not mean that all examples of that category of personal information were in fact sold,
              but reflects our good faith belief to the best of our knowledge that some of that
              information from the applicable category may be and may have been shared for value in
              return.
            </p>
            <p>
              We may sell and may have sold in the last twelve (12) months the following categories
              of personal information:
            </p>
            <p>
              Category A: Identifiers
              <br />
              Category B: Personal information categories listed in the California Customer Records
              statute (Cal. Civ. Code § 1798.80(e))
              <br />
              Category D: Commercial information
              <br />
              Category F: Internet or other similar network activity
            </p>
          </li>
          <li>
            <p>
              <b>7.6 Share of Personal Information</b>
            </p>
            <p>
              We may share Your personal information identified in the above categories with the
              following categories of third parties:
            </p>
            <p>
              Service Providers <br />
              Payment processors <br />
              Our affiliates <br />
              Our business partners <br />
              Third party vendors to whom You or Your agents authorize Us to disclose Your personal
              information in connection with products or services We provide to You
            </p>
          </li>
          <li>
            <p>
              <b>7.7 Sale of Personal Information of Minors Under 16 Years of Age</b>
            </p>
            <p>
              We do not knowingly collect personal information from minors under the age of 16
              through our Service, although certain third party websites that we link to may do so.
              These third-party websites have their own terms of use and privacy policies and we
              encourage parents and legal guardians to monitor their children's Internet usage and
              instruct their children to never provide information on other websites without their
              permission.
            </p>
            <p>
              We do not sell the personal information of Consumers We actually know are less than 16
              years of age, unless We receive affirmative authorization (the "right to opt-in") from
              either the Consumer who is between 13 and 16 years of age, or the parent or guardian
              of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal
              information may opt-out of future sales at any time. To exercise the right to opt-out,
              You (or Your authorized representative) may submit a request to Us by contacting Us.
            </p>
            <p>
              If You have reason to believe that a child under the age of 13 (or 16) has provided Us
              with personal information, please contact Us with sufficient detail to enable Us to
              delete that information.
            </p>
          </li>
          <li>
            <p>
              <b>7.8 Your Rights under the CCPA</b>
            </p>
            <p>
              The CCPA provides California residents with specific rights regarding their personal
              information. If You are a resident of California, You have the following rights:
            </p>
            <p>
              <b>“The right to notice.” </b>You have the right to be notified which categories of
              Personal Data are being collected and the purposes for which the Personal Data is
              being used. <br />
              <b> “The right to request.”</b> Under CCPA, You have the right to request that We
              disclose information to You about Our collection, use, sale, disclosure for business
              purposes and share of personal information. Once We receive and confirm Your request,
              We will disclose to You:
              <br />
              The categories of personal information We collected about You <br />
              The categories of sources for the personal information We collected about You
              <br />
              Our business or commercial purpose for collecting or selling that personal information
              <br />
              The categories of third parties with whom We share that personal information
              <br />
              The specific pieces of personal information We collected about You
              <br />
              If we sold Your personal information or disclosed Your personal information for a
              business purpose, We will disclose to You:
              <br />
              The categories of personal information categories sold
              <br /> The categories of personal information categories disclosed <br />
              <b> “The right to say no to the sale of Personal Data (opt-out).”</b> You have the
              right to direct Us to not sell Your personal information. To submit an opt-out request
              please contact Us. <br />
              <b> “The right to delete Personal Data.”</b> You have the right to request the
              deletion of Your Personal Data, subject to certain exceptions. Once We receive and
              confirm Your request, We will delete (and direct Our Service Providers to delete) Your
              personal information from our records, unless an exception applies. We may deny Your
              deletion request if retaining the information is necessary for Us or Our Service
              Providers to:
              <br />
              Complete the transaction for which We collected the personal information, provide a
              good or service that You requested, take actions reasonably anticipated within the
              context of our ongoing business relationship with You, or otherwise perform our
              contract with You. <br /> Detect security incidents, protect against malicious,
              deceptive, fraudulent, or illegal activity, or prosecute those responsible for such
              activities. <br /> Debug products to identify and repair errors that impair existing
              intended functionality. <br /> Exercise free speech, ensure the right of another
              consumer to exercise their free speech rights, or exercise another right provided for
              by law. <br /> Comply with the California Electronic Communications Privacy Act (Cal.
              Penal Code § 1546 et. seq.). <br /> Engage in public or peer-reviewed scientific,
              historical, or statistical research in the public interest that adheres to all other
              applicable ethics and privacy laws, when the information's deletion may likely render
              impossible or seriously impair the research's achievement, if You previously provided
              informed consent. <br /> Enable solely internal uses that are reasonably aligned with
              consumer expectations based on Your relationship with Us. <br /> Comply with a legal
              obligation. <br /> Make other internal and lawful uses of that information that are
              compatible with the context in which You provided it. <br />
              <b>“The right not to be discriminated against.”</b> You have the right not to be
              discriminated against for exercising any of Your consumer's rights, including by:{' '}
              <br />
              Denying goods or services to You
              <br />
              Charging different prices or rates for goods or services, including the use of
              discounts or other benefits or imposing penalties
              <br />
              Providing a different level or quality of goods or services to You
              <br />
              Suggesting that You will receive a different price or rate for goods or services or a
              different level or quality of goods or services
            </p>
          </li>
          <li>
            <p>
              <b>7.9 Exercising Your CCPA Data Protection Rights</b>
            </p>
            <p>
              In order to exercise any of Your rights under the CCPA, and if You are a California
              resident, You can contact Us.
            </p>
            <p>
              Only You, or a person registered with the California Secretary of State that You
              authorize to act on Your behalf, may make a verifiable request related to Your
              personal information.
            </p>
            <p>
              Your request to Us must: <br />
              Provide sufficient information that allows Us to reasonably verify You are the person
              about whom We collected personal information or an authorized representative
              <br />
              Describe Your request with sufficient detail that allows Us to properly understand,
              evaluate, and respond to it
            </p>
            <p>
              We cannot respond to Your request or provide You with the required information if We
              cannot:
              <br />
              Verify Your identity or authority to make the request
              <br />
              And confirm that the personal information relates to You
            </p>
            <p>
              We will disclose and deliver the required information free of charge within 45 days of
              receiving Your verifiable request. The time period to provide the required information
              may be extended once by an additional 45 days when reasonably necessary and with prior
              notice.
            </p>
            <p>
              Any disclosures We provide will only cover the 12-month period preceding the
              verifiable request's receipt.
            </p>
            <p>
              For data portability requests, We will select a format to provide Your personal
              information that is readily usable and should allow You to transmit the information
              from one entity to another entity without hindrance.
            </p>
          </li>
          <li>
            <p>
              <b>7.10 Do Not Sell My Personal Information</b>
            </p>
            <p>
              You have the right to opt-out of the sale of Your personal information. Once We
              receive and confirm a verifiable consumer request from You, we will stop selling Your
              personal information. To exercise Your right to opt-out, please contact Us.
            </p>
            <p>
              The Service Providers we partner with (for example, our analytics or advertising
              partners) may use technology on the Service that sells personal information as defined
              by the CCPA law. If you wish to opt out of the use of Your personal information for
              interest-based advertising purposes and these potential sales as defined under CCPA
              law, you may do so by following the instructions below.
            </p>
            <p>
              Please note that any opt out is specific to the browser You use. You may need to opt
              out on every browser that You use.
            </p>
            <p className={css.specificSubTitle}>7.10.1 Website</p>
            <p>
              You can opt out of receiving ads that are personalized as served by our Service
              Providers by following our instructions presented on the Service:
            </p>
            <p>
              The NAI's opt-out platform:
              <a href="http://www.networkadvertising.org/choices/">
                (http://www.networkadvertising.org/choices/)
              </a>
              <br />
              The EDAA's opt-out platform
              <a href="http://www.youronlinechoices.com/">(http://www.youronlinechoices.com/)</a>
              <br />
              The DAA's opt-out platform:
              <a href="http://optout.aboutads.info/?c=2&lang=EN">
                (http://optout.aboutads.info/?c=2&lang=EN)
              </a>
            </p>
            <p>
              The opt out will place a cookie on Your computer that is unique to the browser You use
              to opt out. If you change browsers or delete the cookies saved by your browser, You
              will need to opt out again.
            </p>
            <p className={css.specificSubTitle}>7.10.2 - Mobile Devices</p>
            <p>
              Your mobile device may give You the ability to opt out of the use of information about
              the apps You use in order to serve You ads that are targeted to Your interests:
            </p>
            <p>
              <b>"Opt out of Interest-Based Ads"</b> or <b>"Opt out of Ads Personalization"</b> on
              Android devices
              <br />
              <b>"Limit Ad Tracking"</b> on iOS devices
            </p>
            <p>
              You can also stop the collection of location information from Your mobile device by
              changing the preferences on Your mobile device.
            </p>
          </li>
          <li>
            <p>
              <b>7.11 Purchase Provisions</b>
            </p>
            <p>
              When the initial contact between a Buyer and a Trainer (You) is made through the
              Service (PatCom Education Connection), You are prohibited from selling the Training
              through any other manner than the Service to that Buyer. If it is determined that You
              sold Goods to Buyers who contacted You through the Service without using the payment
              processing on the Service, We hold the right to suspend your account from the Service
              and take legal action to recover the lost funds.
            </p>
          </li>
          <li>
            <p>
              <b>
                7.12 "Do Not Track" Policy as Required by California Online Privacy Protection Act
                (CalOPPA)
              </b>
            </p>
            <p>Our Service does not respond to Do Not Track signals.</p>
            <p>
              However, some third party websites do keep track of Your browsing activities. If You
              are visiting such websites, You can set Your preferences in Your web browser to inform
              websites that You do not want to be tracked. You can enable or disable DNT by visiting
              the preferences or settings page of Your web browser.
            </p>
          </li>
          <li>
            <p>
              <b>7.13 Your California Privacy Rights (California's Shine the Light law)</b>
            </p>
            <p>
              Under California Civil Code Section 1798 (California's Shine the Light law),
              California residents with an established business relationship with us can request
              information once a year about sharing their Personal Data with third parties for the
              third parties' direct marketing purposes.
            </p>
            <p>
              If you'd like to request more information under the California Shine the Light law,
              and if You are a California resident, You can contact Us using the contact information
              provided below.
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>8. Children's and Minor Users Privacy Rights</p>
        <ul>
          <li>
            <p>
              <b>8.1 - General Privacy Rights for Children</b>
            </p>
            <p>
              Our Service does not address anyone under the age of 13. We do not knowingly collect
              personally identifiable information from anyone under the age of 13. If You are a
              parent or guardian and You are aware that Your child has provided Us with Personal
              Data, please contact Us. If We become aware that We have collected Personal Data from
              anyone under the age of 13 without verification of parental consent, We take steps to
              remove that information from Our servers.
            </p>
            <p>
              If We need to rely on consent as a legal basis for processing Your information and
              Your country requires consent from a parent, We may require Your parent's consent
              before We collect and use that information.
            </p>
          </li>
          <li>
            <p>
              <b>
                8.2 California Privacy Rights for Minor Users (California Business and Professions
                Code Section 22581)
              </b>
            </p>
            <p>
              California Business and Professions Code Section 22581 allows California residents
              under the age of 18 who are registered users of online sites, services or applications
              to request and obtain removal of content or information they have publicly posted.
            </p>
            <p>
              To request removal of such data, and if You are a California resident, You can contact
              Us using the contact information provided below, and include the email address
              associated with Your account.
            </p>
            <p>
              Be aware that Your request does not guarantee complete or comprehensive removal of
              content or information posted online and that the law may not permit or require
              removal in certain circumstances.
            </p>
          </li>
        </ul>
      </div>
      <div className={css.list}>
        <p>9. Changes to this Privacy Policy</p>
        <ul>
          <li>
            <p>
              We may update Our Privacy Policy from time to time. We will notify You of any changes
              by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our Service, prior to the
              change becoming effective and update the "Last updated" date at the top of this
              Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
